.Navbar {
    background-color: rgb(0, 33, 68);
    font-size: 16pt;
    height: 70pt;
    display: flex;
    flex-direction: row;
}

.Navbar a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    padding-left: 5%;
    width: fit-content;
}

.NavDiv {
    margin-top: 30pt;
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: top;
}

.NavLogo {
    width: 15%;
    max-width: 60pt;
    margin-top: 5pt;
    margin-bottom: 0pt;
    margin-left: 10pt;
}