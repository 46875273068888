.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: row;
  background-color: rgb(0, 33, 68);
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}