.panes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
    margin-top: 20px;
}

.selected {
    background-color: rgb(128, 183, 238);
}

.context-list {
    margin-top: 5pt;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.context-name {
    cursor: pointer;
    width: 100pt;
    border: solid 1pt white;
}

.left-pane {
    min-width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.right-pane {
    min-width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.player-list {
    border: solid 1px white;
    padding-top: 5pt;
    padding-bottom: 5pt;
}

.player-name-selected {
    background-color: rgb(128, 183, 238);
    cursor: pointer;
}

.player-name, .type-name, .outcome-name {
    cursor: pointer;
}

.zones {
    border: solid 1px white;
}

.zone-arrow {
    cursor: pointer;
    font-size: 20pt;
    margin-top: 3pt;
    margin-bottom: 3pt;
}

.zone-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.zone-list-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.zone-name {
    cursor: pointer;
    width: 33.33%;
    font-size: 18pt;
}

.type-list {
    border: solid 1px white;
}

.outcome-label {
    font-size: 8pt;
    margin-top: -2pt;
    color: #aaa;
}

.outcome-list {
    border: solid 1px white;
}

.rally-outcome-list {
    border: solid 1px white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rally-outcome-name {
    cursor: pointer;
    width: 50%;
    font-size: 15pt;
}

.velocity-input {
    margin-top: 15pt;
}

#capture-button {
    margin-top: 15pt;
    margin-right: 5pt;
}

#velocities {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;
    height: 140px;
    margin-bottom: 100pt;
}

.serve-velocity-clickable {
    cursor: pointer;
    min-width: 120px;
    margin-left: 30px;
    margin-right: 30px;
}

.serve-velocity {
    min-width: 120px;
    margin-left: 30px;
    margin-right: 30px;
}

.capture-status {
    height: 25px;
    margin-top: 10pt;
    margin-bottom: 10pt;
}

.serve-image-small {
    max-height: 80px;
    margin-top: 10px;
  }