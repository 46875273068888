.chart-area {
    font-size: 14px;
    max-width: 600px;
}

.serve-count {
    font-size: 22px;
    color: #aaa;
}

.title {
    margin-top: 20px;
    font-size: 32px;
}

.app-logo {
    width: 100px;
    margin-top: 10px;
}

.filter-label {
    font-size: 14px;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    column-gap: 4px;
}

.dates {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: baseline;
    margin-top: 10px;
    column-gap: 6px;
}

.player-filter {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: baseline;
    margin-top: 10px;
    column-gap: 6px;
}

.serve-type-filter {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: baseline;
    margin-top: 10px;
    column-gap: 6px;
}

.filter-area {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    flex-direction: row;
    align-content: baseline;
    justify-content: center;
    margin-top: 10px;
    column-gap: 6px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border: 1px solid #eaa22e ;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 600px;
}

.selected-option {
    font-size:14px;
}

.flex-break {
    flex-basis: 100%;
    width: 100%;
}

.recharts-default-legend {
    margin-bottom: 5px !important;
}

.custom-tooltip {
    font-size: 12px;
    row-gap: 2px;
    background-color: rgba(100, 100, 100, 0.6);
    display: flex;
    flex-direction: column;
    row-gap: 0;
    column-gap: 0;
    padding: 2px;
}

.sample-label {
    font-size: 12px;
    margin: 2px;
}

.eps-label {
    font-size: 12px;
    margin: 2px;
    color: #eaa22e;
}

.err-label {
    font-size: 12px;
    margin: 2px;
    color: #ccc;
}

@media only screen and (max-width: 650px) {
    .chart-area {
        width: 90%;
    }
}

