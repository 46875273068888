.panelButton {
    margin-top: 10pt;
    margin-left: 5pt;
    padding: 5pt;
    border: 2pt solid white;
    border-radius: 5pt;
    color: white;
    background-color: rgb(0, 33, 68);
}

.panelButton:hover {
    color: rgb(0, 33, 68);
    background-color: white;
}

.panelButton:disabled {
    color: gray;
    border: 2pt solid gray;
    background-color: rgb(0, 33, 68);
}

.display-linebreak {
    white-space: pre-line;
  }
