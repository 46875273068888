.App {
  text-align: center;
  background-color: rgb(0, 33, 68);
  color: white;
}

.App-header {
  background-color: rgb(0, 33, 68);
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ServePanel {
  background-color: rgb(0, 33, 68);
  font-size: calc(10px + 6vmin);
}

.LogoImage {
  width: 100px;
  margin-bottom: -5vh
}

.ServeImage {
  width: 200px;
}

.endDiv {
  margin-top: 350px;
}